import { AccountCategory } from '@prisma/client'

export const ACCESS_TOKEN_COOKIE = 'efirma-app-access-token'
export const REFRESH_TOKEN_COOKIE = 'efirma-app-refresh-token'
export const PRIVACY_POLICY_COOKIE = 'efirma-privacy-agreed'
export const I18N_COOKIE = 'i18n_redirected'
export const REQUEST_INPUT_DEBOUNCE_MS = 500
export const APP_LOCALE_HEADER = 'X-App-Locale'
export const EUR_BGN_RATE = 1.95583

export const passwordRequirements = {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
} as const

/**
 * Map of account categories to their respective account class
 *
 * @see https://www.minfin.bg/bg/1038
 */
export const accountClass: Record<AccountCategory, number> = {
    [AccountCategory.FIXED_ASSETS]: 2,
    [AccountCategory.ESTIMATES]: 4,
    [AccountCategory.EXPENSES]: 6,
    [AccountCategory.REVENUES]: 7,
} as const

export const supportedCountries = [
    'AT', // Austria
    'BE', // Belgium
    'BG', // Bulgaria
    'HR', // Croatia
    'CY', // Cyprus
    'CZ', // Czech Republic
    'DK', // Denmark
    'EE', // Estonia
    'FI', // Finland
    'FR', // France
    'DE', // Germany
    'GR', // Greece
    'HU', // Hungary
    'IE', // Ireland
    'IT', // Italy
    'LV', // Latvia
    'LT', // Lithuania
    'LU', // Luxembourg
    'MT', // Malta
    'NL', // Netherlands
    'NO', // Norway
    'PL', // Poland
    'PT', // Portugal
    'RO', // Romania
    'SK', // Slovakia
    'SI', // Slovenia
    'ES', // Spain
    'SE', // Sweden
    'TR', // Turkey
    'GB', // United Kingdom
    'US', // United States
]
